.wrapper {
  position: relative;
}
.header {
  /* height: 0; */
  overflow: hidden;
  opacity: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  cursor: all-scroll;
}

.wrapper.isEditing .header,
.wrapper:hover .header {
  height: auto;
  opacity: 1;
  padding: 0.5rem;
  /* top: -100%; */
  margin-bottom: 1rem;
  z-index: 20;
}

.header:hover + .content {
  opacity: 0.4;
  transform: scale(0.9);
}
