@layer components {
  .modal {
    @apply inline-block text-left shadow-xl transform transition-all align-middle w-full dark:text-blue-100 rounded-lg p-6;
  }
  .modal.sm {
    @apply max-w-sm;
  }
  .modal.lg {
    @apply max-w-lg;
  }
  .modal.xl {
    @apply max-w-2xl;
  }
}
