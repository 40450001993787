.firebaseui-idp-text.firebaseui-idp-text-long,
.firebaseui-idp-text.firebaseui-idp-text-short,
.firebaseui-container {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji" !important;
}
.mdl-button--primary.mdl-button--primary,
.mdl-button--colored {
  @apply bg-gray-600 hover:bg-gray-500  !important;
}

.firebaseui-id-submit,
.mdl-button {
  @apply bg-green-400 dark:bg-green-400 text-green-50 hover:bg-green-500 rounded-md  shadow hover:shadow-lg !important;
}
.firebaseui-idp-google > .firebaseui-idp-text {
  @apply text-white !important;
}
.firebaseui-container {
  /* background: rgba(0, 0, 0, 0.05); */
  background-color: transparent !important;
  box-shadow: none !important;
  font: inherit;
  min-height: 150px;
}
.firebaseui-container.firebaseui-page-provider-sign-in {
  /* background: transparent; */
  box-shadow: none;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-container.firebaseui-id-page-callback {
  /* background: transparent; */
  box-shadow: none;
  margin-top: 40px;
  height: 84px;
  min-height: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.firebaseui-card-header {
  display: none;
}
.firebaseui-subtitle,
.firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: rgba(0, 0, 0, 0.1);
}
.firebaseui-id-dismiss-info-bar {
  display: block;
}
.firebaseui-info-bar {
  border: 0;
  border-radius: 10px;
  left: 5%;
  right: 5%;
  top: 10%;
  bottom: 10%;
}

.mdl-textfield__input {
  @apply dark:text-blue-100 dark:bg-gray-800   block w-full rounded-md  text-lg !important;
}
