.react-calendar {
  max-width: 100%;
  max-height: 100%;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  margin-bottom: 0.5em;

  @apply rounded-tr-md;
  @apply rounded-tl-md;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  text-align: center;
  text-transform: uppercase;
  @apply text-sm;
  @apply font-mono;
  @apply text-primary dark:text-primaryDark;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.8rem;
  @apply text-primary dark:text-primaryDark;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekdays__weekday {
  @apply font-mono;
  @apply text-primary dark:text-primaryDark;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day {
  @apply text-primary dark:text-primaryDark;
}
.react-calendar__month-view__days__day--weekend {
  @apply text-highlight dark:text-highlightDark;
}
.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-tertiary dark:text-tertiaryDark;
}
.react-calendar__tile {
  padding: 0.5rem;
  background-color: transparent;
}
/* .react-calendar__tile:disabled,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  @apply bg-tertiary dark:bg-tertiaryDark;
  @apply text-background dark:text-backgroundDark;
} */
.react-calendar__tile--now {
  @apply bg-primary dark:bg-primaryDark;
  @apply text-background dark:text-backgroundDark;
  @apply rounded-lg;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  @apply bg-tertiary dark:bg-tertiaryDark;
  @apply text-background dark:text-backgroundDark;
}
.react-calendar__tile--hasActive {
  background: pink;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  @apply bg-tertiary dark:bg-tertiaryDark;
  @apply text-background dark:text-backgroundDark;
}
.react-calendar__tile--active {
  @apply bg-tertiary dark:bg-tertiaryDark;
  @apply text-background dark:text-backgroundDark;
}
/* .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  @apply bg-tertiary dark:bg-tertiaryDark;
  @apply text-background dark:text-backgroundDark;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
} */
