input[type="text"]:disabled {
  @apply bg-gray-100 dark:bg-gray-700;
}

aside.emoji-picker-react {
  box-shadow: none;
  @apply dark:text-blue-100 dark:bg-gray-800  focus:ring-blue-600 focus:border-blue-600  w-full rounded-md  dark:border-gray-600 border-gray-300 text-lg;
}
aside.emoji-picker-react .emoji-categories {
  @apply bg-blue-100 dark:bg-gray-300 text-blue-800 dark:text-blue-100;
}
aside.emoji-picker-react .emoji-group {
  padding: 0;
}
aside.emoji-picker-react .emoji-group:before {
  @apply px-2 bg-blue-100 dark:bg-gray-700 text-blue-800 dark:text-blue-100;
}
