.rssHtmlFields {
  @apply text-sm;
}
.rssHtmlFields img {
  @apply rounded-lg my-1;
}

.titleImage {
  max-width: 60px;
  @apply rounded-md mr-3;
}
