@layer components {
  .btn {
    @apply px-4 py-2 mx-1 transition-colors rounded-lg text-base font-medium flex flex-row items-center justify-between;
  }
  .btn.link {
    @apply bg-transparent;
  }

  .btn-success {
    @apply bg-green-400 dark:bg-green-400 text-green-50 hover:bg-green-500;
  }
  .btn-success.outline {
    @apply bg-transparent dark:bg-transparent border border-green-400 text-green-400 hover:bg-green-500 hover:text-green-50;
  }
  .btn-success.light {
    @apply bg-green-200 hover:bg-green-100;
  }
  .btn-success.dark {
    @apply bg-green-600 hover:bg-green-700;
  }
  .btn-success:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .btn-success:hover:not(:disabled) {
    cursor: pointer;
  }

  .btn-secondary {
    @apply bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-800;
  }
  .btn-secondary.outline {
    @apply bg-transparent dark:bg-transparent border border-gray-400 text-gray-400 hover:bg-gray-500 hover:text-gray-50;
  }
  .btn-secondary.light {
    @apply bg-gray-600 hover:bg-gray-500;
  }
  .btn-secondary.dark {
    @apply bg-gray-900 hover:bg-gray-800;
  }
  .btn-secondary:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .btn-secondary:hover:not(:disabled) {
    cursor: pointer;
  }

  .btn-default {
    @apply bg-blue-400 dark:bg-blue-400 text-blue-50 hover:bg-blue-500;
  }
  .btn-default.outline {
    @apply bg-transparent dark:bg-transparent border border-blue-400 text-blue-400 hover:bg-blue-500 hover:text-blue-50;
  }
  .btn-default.light {
    @apply bg-blue-200 hover:bg-blue-100;
  }
  .btn-default.dark {
    @apply bg-blue-600 hover:bg-blue-700;
  }
  .btn-default:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .btn-default:not(:disabled) {
    cursor: pointer;
  }

  .btn-info {
    @apply bg-pink-400 dark:bg-pink-400 text-pink-50 hover:bg-pink-500;
  }
  .btn-info.outline {
    @apply bg-transparent dark:bg-transparent border border-pink-400 text-pink-400 hover:bg-pink-500 hover:text-pink-50;
  }
  .btn-info.light {
    @apply bg-pink-200 hover:bg-pink-100 text-white;
  }
  .btn-info.dark {
    @apply bg-pink-600 hover:bg-pink-700;
  }
  .btn-info:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .btn-info:not(:disabled) {
    cursor: pointer;
  }

  .btn-danger {
    @apply bg-red-400 dark:bg-red-400 text-red-50 hover:bg-red-500;
  }
  .btn-danger.outline {
    @apply bg-transparent dark:bg-transparent border border-red-400 text-red-400 hover:bg-red-500 hover:text-red-50;
  }
  .btn-danger.light {
    @apply bg-red-200 hover:bg-red-100;
  }
  .btn-danger.dark {
    @apply bg-red-600 hover:bg-red-700;
  }
  .btn-danger:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .btn-danger:not(:disabled) {
    cursor: pointer;
  }
}
