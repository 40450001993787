@layer components {
  .base-01 {
    @apply bg-white dark:bg-gray-900 text-blue-900 dark:text-blue-200;
  }
  .base-02 {
    @apply bg-blue-100 dark:bg-gray-600 text-blue-800 dark:text-blue-100;
  }
  .base-03 {
    @apply bg-blue-200 dark:bg-gray-500 text-blue-800 dark:text-blue-100;
  }

  .bg-success {
    @apply bg-green-400 dark:bg-green-400 text-green-50;
  }
  .bg-success.light {
    @apply bg-green-200;
  }
  .bg-success.dark {
    @apply bg-green-500 dark:bg-green-500;
  }

  .bg-default {
    @apply bg-gray-400 dark:bg-gray-400 text-gray-50;
  }
  .bg-default.light {
    @apply bg-gray-200;
  }
  .bg-default.dark {
    @apply bg-gray-600;
  }

  .bg-info {
    @apply bg-blue-400 dark:bg-blue-400 text-blue-50;
  }
  .bg-info.light {
    @apply bg-blue-200;
  }
  .bg-info.dark {
    @apply bg-blue-600;
  }

  .bg-danger {
    @apply bg-red-400 dark:bg-red-400 text-red-50;
  }
  .bg-danger.light {
    @apply bg-red-200;
  }
  .bg-danger.dark {
    @apply bg-red-600;
  }

  .bg-warning {
    @apply bg-yellow-400 dark:bg-yellow-400 text-yellow-50;
  }
  .bg-warning.light {
    @apply bg-yellow-200;
  }
  .bg-warning.dark {
    @apply bg-yellow-600;
  }
}
