@layer components {
  .border-base-01 {
    @apply border-2 border-gray-100 dark:border-gray-800;
  }
  .border-base-02 {
    @apply border-2 border-gray-200 dark:border-gray-600;
  }
  .border-base-03 {
    @apply bg-gray-300 dark:bg-gray-500;
    @apply border-2 border-gray-400;
  }

  .border-success {
    @apply border-green-400 dark:border-green-600;
  }

  .border-default {
    @apply border-gray-400 dark:border-gray-600;
  }

  .border-info {
    @apply border-blue-400 dark:border-blue-600;
  }

  .border-danger {
    @apply border-red-400 dark:border-red-600;
  }
}
