@tailwind base;
@tailwind components;
@tailwind utilities;

@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

@import "./base.css";
@import "./modals.css";
@import "./buttons.css";
@import "./borders.css";
@import "./icons.css";
@import "./forms.css";

body,
html,
#root {
  height: 100%;
}

.large-letter-logo {
  width: 42px;
  height: 42px;
  text-transform: uppercase;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-container {
  height: calc(100vh - 60px);
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.react-grid-item.react-grid-placeholder {
  background-color: rgba(59, 130, 246, 0.5);
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border-radius: 0.375rem;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  min-height: 100%;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-draggable-dragging {
  @apply bg-blue-100 dark:bg-blue-900 shadow-2xl;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 10px;
  height: 10px;
  background-color: transparent;
  /* border-width: 5px; */
  @apply dark:bg-gray-700;
  @apply border-blue-600;
  @apply border-b-2;
  @apply border-r-2;
  /* border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4); */
}
